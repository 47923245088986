import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

function NotFoundPage() {
  return (
    <section className="px-2 py-6 md:px-16">

      <SEO title="404: Not found" />
      <div>
        <h2 className="text-2xl inline-block my-8 p-3">
          Opa, aconteceu alguma coisa errada...
        </h2>
        <p>
          Mas não se preocupe, nosso time já está avaliando o que ocorreu!
        </p>
      </div>

    </section>
  );
}

export default NotFoundPage;
